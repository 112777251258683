import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { fetchGetAdsFilters, fetchGetAdsFilterModelByBrand } from "../../../services/api";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, EffectFade, Autoplay } from "swiper";
import Image from "next/image";

export default function HomeHero({ banners }) {
    const router = useRouter();
    const { handleSubmit, register, setValue } = useForm();
    const [filters, setFilters] = useState({ isLoading: true, brands: [], models: [], yearsModel: [], transmissions: [], fuels: [] });
    const [filterBrand, setFilterBrand] = useState({ isLoading: false, brand: "" });

    useEffect(() => {
        if (filters.isLoading) {
            fetchGetAdsFilters({ condition: null })
                .then(res => {
                    if (res) { setFilters({ isLoading: false, ...res }); }
                })
                .catch(() => { setFilters({ ...filters, isLoading: false }); });
        }
    }, [filters]);

    useEffect(() => {
        if (filterBrand.isLoading) {
            fetchGetAdsFilterModelByBrand({ brand: filterBrand.brand })
                .then(res => {
                    if (res) { setFilters(prevFilters => ({ ...prevFilters, isLoading: false, models: res.models })); }
                    setFilterBrand({ ...filterBrand, isLoading: false });
                })
                .catch(() => { setFilterBrand({ ...filterBrand, isLoading: false }); });
        }
        setValue('ddlModel', "");
    }, [filterBrand, filters, setValue]);

    const handleBrandChange = (event) => {
        let idBrand = event.target.value;
        if (idBrand)
            setFilterBrand({ ...filterBrand, isLoading: true, brand: idBrand });
        else setFilters({ ...filters, isLoading: true });
    };

    const onSubmit = (data) => {
        if (data.ddlBrand) router.query.marca = data.ddlBrand;
        if (data.ddlModel) router.query.modelo = data.ddlModel;
        if (data.ddlPriceFrom) router.query.preco_de = data.ddlPriceFrom;
        if (data.ddlPriceTo) router.query.preco_ate = data.ddlPriceTo;
        router.push(`/estoque?${new URLSearchParams(router.query)}`);
    }

    return (
        <>
            {
                banners.length > 0
                    ? <section className="dealership-hero position-relative overflow-hidden">
                        <Swiper
                            autoplay={true}
                            loop={true}
                            speed={1500}
                            effect={'fade'}
                            fadeEffect={{ crossFade: true }}
                            pagination={{ clickable: true }}
                            modules={[Pagination, EffectFade, Autoplay]}>
                            {
                                banners.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="dl-hero-single">
                                            <div className="at_hero_slider">
                                                <div className="at-hero-banner">
                                                    <Image
                                                        src={item.banner.imageUrl}
                                                        alt="banner"
                                                        width={1440}
                                                        height={450}
                                                        sizes="100vw"
                                                        priority={true}
                                                        style={{
                                                            width: '100%',
                                                            // height: 'auto',
                                                            objectFit: 'cover'
                                                        }} />
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </section>
                    : null
            }
            <div className="at-search-box overflow-hidden">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="at-search-box-filter bg-white">
                                <div className="tab-content">
                                    <form className="at-search-filter d-flex align-items-center" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="input-field">
                                            <label htmlFor="ddlBrand">Marca</label>
                                            <select className="form-select" id="ddlBrand" {...register("ddlBrand")} onChange={handleBrandChange} aria-label="Escolha a marca">
                                                <option value={''}></option>
                                                {filters.brands?.map((item, index) => <option value={item.name} key={index}>{item.name}</option>)}
                                            </select>
                                        </div>
                                        <div className="input-field">
                                            <label htmlFor="ddlModel">Modelo</label>
                                            <select className="form-select" id="ddlModel" {...register("ddlModel")} aria-label="Escolha o modelo">
                                                <option value={''}></option>
                                                {filters.models.map((item, index) => <option value={item.name} key={index}>{item.name}</option>)}
                                            </select>
                                        </div>
                                        <div className="input-field">
                                            <label htmlFor="ddlPriceFrom">Valor De</label>
                                            <select className="form-select" id="ddlPriceFrom" {...register("ddlPriceFrom")} aria-label="Escolha o valor inicial">
                                                <option value=''></option>
                                                <option value='10000'>R$ 10.000,00</option>
                                                <option value='20000'>R$ 20.000,00</option>
                                                <option value='30000'>R$ 30.000,00</option>
                                                <option value='40000'>R$ 40.000,00</option>
                                                <option value='50000'>R$ 50.000,00</option>
                                                <option value='60000'>R$ 60.000,00</option>
                                                <option value='70000'>R$ 70.000,00</option>
                                                <option value='80000'>R$ 80.000,00</option>
                                                <option value='90000'>R$90.000,00</option>
                                                <option value='100000'>R$ 100.000,00</option>
                                                <option value='200000'>R$ 200.000,00</option>
                                                <option value='300000'>R$ 300.000,00</option>
                                                <option value='400000'>R$ 400.000,00</option>
                                                <option value='500000'>R$ 500.000,00</option>
                                            </select>
                                        </div>
                                        <div className="input-field">
                                            <label htmlFor="ddlPriceTo">Valor Até</label>
                                            <select className="form-select" id="ddlPriceTo" {...register("ddlPriceTo")} aria-label="Escolha o valor final">
                                                <option value=''></option>
                                                <option value='10000'>R$ 10.000,00</option>
                                                <option value='20000'>R$ 20.000,00</option>
                                                <option value='30000'>R$ 30.000,00</option>
                                                <option value='40000'>R$ 40.000,00</option>
                                                <option value='50000'>R$ 50.000,00</option>
                                                <option value='60000'>R$ 60.000,00</option>
                                                <option value='70000'>R$ 70.000,00</option>
                                                <option value='80000'>R$ 80.000,00</option>
                                                <option value='90000'>R$90.000,00</option>
                                                <option value='100000'>R$ 100.000,00</option>
                                                <option value='200000'>R$ 200.000,00</option>
                                                <option value='300000'>R$ 300.000,00</option>
                                                <option value='400000'>R$ 400.000,00</option>
                                                <option value='500000'>R$ 500.000,00</option>
                                            </select>
                                        </div>
                                        <div className="submit-btn align-self-end">
                                            <button className="btn btn-secondary btn-md" type="submit">Buscar Agora</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}